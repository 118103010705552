























































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {User} from "@/api";
import {RoleSettings} from "@/model/role-settings";
import {defaultRoleSettings} from "@/constants/role.constants";

const Auth = namespace("Auth");

@Component
export default class Profile extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  }

  roleSettings(role: string): RoleSettings {
    return defaultRoleSettings.find(r => r.authority === role)!
  }
}
